window.lui_map_responses = {};

application.register("part_dialog_cart", class extends Stimulus.Controller {
    connect() {
        if(typeof this.element.dataset.free !== "undefined") {
            this._freeCount =  parseInt(this.element.dataset.free)
            this._selectedCount = 0;
            this._maxCount = this._freeCount;

            Array.from(this.targets.findAll('count')).forEach(input => {
                input.setAttribute('max',this._maxCount)
            })
        }
    }

    handleCount(e) {
        const value = parseInt(e.currentTarget.value);
        this._selectedCount = 0;

        if(value > 0) {
            e.currentTarget.closest('.part_ui_input').classList.add('text--color-a')
        } else {
            e.currentTarget.closest('.part_ui_input').classList.remove('text--color-a')
        }

        Array.from(this.targets.findAll('count')).forEach(input => {
            this._selectedCount += parseInt(input.value)
        })

        this._maxCount = this._freeCount - this._selectedCount;

        Array.from(this.targets.findAll('count')).forEach(input => {
            if(input !== e.currentTarget) {
                input.setAttribute('max',Math.min(this._freeCount, this._maxCount + parseInt(input.value)))
            }
        })

        if(this._selectedCount <= this._freeCount) {
            this.targets.find('confirm').removeAttribute('disabled')
        } else {
            this.targets.find('confirm').setAttribute('disabled',true)
        }
    }
});
