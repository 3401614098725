application.register("layout_header", class extends Stimulus.Controller {
    get offsetTop() {
        if (this._offsetTop === undefined) {
            this._offsetTop = 0
        }
        return this._offsetTop
    }
    get ref() {
        return {
            searchArea: this.targets.find("header-search"),
            headerBanner: this.targets.find("header-banner")
        }
    }
    connect() {
        if (typeof this.ref.headerBanner !== "undefined") {
            if (localStorage.getItem("bannerClosed") !== `version-${this.ref.headerBanner.dataset.version}`) {
                this.element.classList.add("has--banner");
                this.ref.headerBanner.removeAttribute("hidden");
            }
        }
        let self = this;
        let lastScrollTop = 0;
        let scrollTimout;
        let header = this.element;
        this._subTimer = "";

        function setHeaderState(position) {
            if (position >= self._offsetTop) {
                header.classList.add("headroom--not-top");
            } else {
                header.classList.remove("headroom--not-top", "headroom--pinned", "headroom--unpinned");
            }

            clearTimeout(scrollTimout);
            scrollTimout = setTimeout(()=>{
                if (position > lastScrollTop){
                    header.classList.remove("headroom--pinned");
                    header.classList.add("headroom--unpinned");
                } else {
                    header.classList.remove("headroom--unpinned");
                    header.classList.add("headroom--pinned");
                }

                lastScrollTop = position <= 0 ? 0 : position;
            },5);
        }

        cssLoaded(function() {
            self._offsetTop = document.getElementById("layout_header").querySelector(".wrp_header_nav").offsetTop;
            setTimeout(() => {
                setHeaderState(window.scrollY);

                if (!document.documentElement.classList.contains("ie")) {
                    window.addEventListener("scroll", () => {
                        let position = window.scrollY;
                        setHeaderState(position);
                    });
                } else {
                    window.addEventListener("scroll", debounce(() => {
                        let position = window.scrollY;
                        setHeaderState(position);
                    }, 100));
                }
            }, 1000);
        });
    }

    bannerClick() {
        gtag('event', 'select_content', {
            "promotions": [
                {
                    "id": "1",
                    "name": "Horní lišta"
                }
            ]
        });
    }

    bannerClose() {
        let self = this;
        self.element.classList.remove("has--banner");
        localStorage.setItem("bannerClosed", `version-${this.ref.headerBanner.dataset.version}`);
        self.ref.headerBanner.parentNode.removeChild(this.ref.headerBanner);
        self._offsetTop = document.getElementById("layout_header").querySelector(".wrp_header_body").offsetTop;
    }

    searchOpen() {
        this.element.classList.add("has--search");
        this.ref.searchArea.classList.add("state--active");
        this.ref.searchArea.querySelector("input").focus();

        if(window.innerWidth < 960) {
            document.body.classList.add("body--no-scroll");
        }
        else {
            document.removeEventListener('click', this.searchCloseDesktop, false);

            setTimeout(()=> {
                document.addEventListener('click', this.searchCloseDesktop, false);
            },300);
        }
    }

    stateActive(e) {
        let currentTarget = e.currentTarget;
        [...this.element.querySelectorAll(".elm_nav_item")].map((selector) => selector.classList.remove("state--active"));
        currentTarget.classList.add("state--active");
    }

    searchClose(event) {
        event.preventDefault();
        this.element.classList.remove("has--search");
        this.ref.searchArea.classList.remove("state--active");
        document.body.classList.remove("body--no-scroll");

        document.removeEventListener('click', this.searchCloseDesktop, false);
    }

    searchCloseDesktop(event) {
        if (event.target.closest("[data-target=\"layout_header.header-search\"]") === null) {
            this.searchClose(event);
        }
    }

    showSub(event) {
        let elm = event.currentTarget;
        let sub = elm.querySelector(".elm_nav_sub");

        if(sub !== null) {
            clearTimeout(this._subTimer);
            elm.querySelector(".elm_nav_item").classList.add("state--hover");
            sub.classList.remove("state--hiding");
            sub.classList.add("state--active");
        }
    }

    hideSub(event) {
        let elm = event.currentTarget;
        let sub = elm.querySelector(".elm_nav_sub");

        if(sub !== null) {
            clearTimeout(this._subTimer);
            sub.classList.add("state--hiding");
            elm.querySelector(".elm_nav_item").classList.remove("state--hover");

            this._subTimer = setTimeout(()=>{
                sub.classList.remove("state--hiding", "state--active");
            },300);
        }
    }
});
