const LibGallery = function (element, index) {
    importStyle(cdnjs.fancybox_css);
    importScript([cdnjs.jquery, cdnjs.fancybox], ()=> {
        cssLoaded(()=> {
            let elm = $(element);
            elm.fancybox({
                selector: `[data-lib-gallery-id="${index}"] .lib--fancybox-item`,
                transitionEffect: "slide",
                animationEffect: "zoom",
                hideScrollbar: false,
                loop: true,
                buttons: [
                    "close"
                ]
            });

            swup.on('contentReplaced', function () {
                $.fancybox.destroy();
            });
        });
    });
};