const LibNoUI = function (element, options) {
    let fn = {
        this: element,
        slider: element.querySelector("[data-range-slider]"),
        elm_min: options.elm_min,
        elm_min_hidden: options.elm_min_hidden,
        elm_max: options.elm_max,
        elm_max_hidden: options.elm_max_hidden,
        unit: options.unit,
        currencyCourse: element.dataset.currencyCourse,
        currencyDecimals: element.dataset.decimals,
        currencyPlacement: element.dataset.currencyPlacement,
    };

    if (typeof fn.unit === "undefined") {
        fn.unit = "";
    }

    let autoNumeric_min;
    let autoNumeric_max;

    if (typeof fn.elm_min !== "undefined") {
        autoNumeric_min = new AutoNumeric(fn.elm_min, {
            digitGroupSeparator: ' ',
            decimalPlaces: (typeof fn.currencyDecimals === "undefined") ? "0" : parseInt(fn.currencyDecimals),
            minimumValue: "0",
            maximumValue: "9999999999",
            unformatOnHover: false,
            currencySymbol : fn.unit+' ',
            currencySymbolPlacement: (typeof fn.currencyPlacement === "undefined") ? "s" : fn.currencyPlacement,
            watchExternalChanges: true
        });
    }

    if (typeof fn.elm_max !== "undefined") {
        autoNumeric_max = new AutoNumeric(fn.elm_max, {
            digitGroupSeparator: ' ',
            decimalPlaces: (typeof fn.currencyDecimals === "undefined") ? "0" : parseInt(fn.currencyDecimals),
            minimumValue: "0",
            maximumValue: "9999999999",
            unformatOnHover: false,
            currencySymbol : fn.unit+' ',
            currencySymbolPlacement: (typeof fn.currencyPlacement === "undefined") ? "s" : fn.currencyPlacement,
            watchExternalChanges: true
        });
    }

    if (fn.slider !== null) {
        let min_val;
        let max_val;
        if (typeof fn.elm_min !== "undefined") {
            min_val = autoNumeric_min.rawValue;
        }
        if (typeof fn.elm_max !== "undefined") {
            max_val = autoNumeric_max.rawValue;
        }

        let slider_min = parseFloat(fn.slider.dataset.min),
            slider_max = parseFloat(fn.slider.dataset.max),
            slider_step = parseFloat(fn.slider.dataset.step);

        if (fn.this.classList.contains("mod--type-single")) {
            noUiSlider.create(fn.slider, {
                start: max_val,
                step: slider_step,
                connect: [true,false],
                range: {
                    'min': slider_min,
                    'max': slider_max
                }
            });

            fn.elm_max.addEventListener('change', function () {
                if (fn.elm_max.value !== "") {
                    fn.slider.noUiSlider.set(autoNumeric_max.rawValue);
                }
            });
        } else {
            noUiSlider.create(fn.slider, {
                start: [min_val, max_val],
                step: slider_step,
                connect: true,
                range: {
                    'min': slider_min,
                    'max': slider_max
                }
            });

            fn.elm_max.addEventListener('change', function () {
                if (fn.elm_max.value !== "") {
                    fn.slider.noUiSlider.set([autoNumeric_min.rawValue, autoNumeric_max.rawValue]);
                }
            });

            fn.elm_min.addEventListener('change', function () {
                if (fn.elm_min.value !== "") {
                    fn.slider.noUiSlider.set([autoNumeric_min.rawValue, autoNumeric_max.rawValue]);
                }
            });
        }

        fn.slider.noUiSlider.on('update', function (values, handle) {
            let value = values[handle];
            if (!handle) {
                if (typeof fn.elm_min_hidden !== "undefined") {
                    fn.elm_min_hidden.value = Math.round(value);
                }

                if (typeof fn.elm_min !== "undefined") {
                    autoNumeric_min.set(Math.round(value));
                }
            }
            if (handle || typeof fn.elm_min === "undefined") {
                if (typeof fn.elm_max_hidden !== "undefined") {
                    fn.elm_max_hidden.value = Math.round(value);
                }

                if (typeof fn.elm_max !== "undefined") {
                    autoNumeric_max.set(Math.round(value));
                }
            }
        });


        fn.slider.noUiSlider.on('change', function (values, handle) {
            if (!handle) {
                if (typeof fn.elm_min !== "undefined") {
                    if (autoNumeric_min.rawValue !== "") {
                        fn.elm_min.dispatchEvent(new Event('change', { 'bubbles': true }));
                    }
                }
                if (typeof fn.elm_min_hidden !== "undefined") {
                    fn.elm_min_hidden.dispatchEvent(new Event('change', { 'bubbles': true }));
                }
            }
            if (handle || typeof fn.elm_min === "undefined") {
                if (typeof fn.elm_max !== "undefined") {
                    if (autoNumeric_max.rawValue !== "") {
                        fn.elm_max.dispatchEvent(new Event('change', { 'bubbles': true }));
                    }
                }
                if (typeof fn.elm_max_hidden !== "undefined") {
                    fn.elm_max_hidden.dispatchEvent(new Event('change', { 'bubbles': true }));
                }
            }
        });
    }
};