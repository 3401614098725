application.register("lib-expand", class extends Stimulus.Controller {
    connect() {
        this._isInitialized = false;
    }

    expand(element) {
        let body = element.querySelector("[data-lib-expand-body]");
        body.style.height = body.scrollHeight + "px";
        element.classList.add("state--active");
    }

    collapse(element) {
        let body = element.querySelector("[data-lib-expand-body]");

        if (!self._isInitialized) {
            body.style.height = body.offsetHeight + "px";
            body.offsetHeight;
            self._isInitialized = true;
        }

        body.style.height = "0px";
        element.classList.remove("state--active");
    }

    toggle() {
        let body = this.element.querySelector("[data-lib-expand-body]");

        if (body.offsetHeight === 0) {
            this.expand(this.element);
        } else {
            this.collapse(this.element);
        }

        this.adjustParent(this.element);
    }

    adjustParent(element) {
        let parentExpand = element.closest("[data-lib-expand-body]");

        if (parentExpand !== null) {
            parentExpand.style.height = null;
            parentExpand.offsetHeight;
            setTimeout(() => {
                parentExpand.style.height = parentExpand.scrollHeight + "px";
            }, 350);
        }
    }
});