window.lui_nav_submenu = {};

application.register("layout_nav", class extends Stimulus.Controller {

    get target() {
        return {
            body: this.targets.find("body"),
            sub: this.targets.find("sub")
        }
    }

    openSub(e) {
        let elm = e.currentTarget;
        let url = elm.dataset.actionUrl;

        if (typeof lui_nav_submenu[url] === "undefined") {
            elm.classList.add("state--loading");
            fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                return response.json();
            }).then((data) => {
                lui_nav_submenu[url] = data.snippet;
                this.populateSub(data.snippet);
                elm.classList.remove("state--loading");
            });
        } else {
            this.populateSub(lui_nav_submenu[url]);
        }
    }

    closeSub(e) {
        this.element.classList.remove("is--sub-active");
    }

    populateSub(snippet) {
        if(new DOMParser().parseFromString(snippet, 'text/html').body.childElementCount > 0) {
            this.target.sub.innerHTML = "";
            this.target.sub.insertAdjacentHTML("beforeend",snippet);
            this.target.body.scrollTop = 0;
            this.element.classList.add("is--sub-active");
        }
    }

});