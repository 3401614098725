application.register("comp_head", class extends Stimulus.Controller {
    get ref() {
        return {
            text: this.targets.find("text")
        }
    }
    connect() {
        let selector = this.element;

        if (typeof this.ref.text !== "undefined") {
            this._textHeightInitial = this.ref.text.clientHeight;
            this._textHeight = this.ref.text.scrollHeight;

            if(this._textHeightInitial < this._textHeight) {
                selector.querySelector(`[data-action*="showMore"]`).style.display = "block";
            }

            // let pCount = selector.querySelectorAll(`[data-target="comp_head.text"] > div > p`).length;
            // if(pCount && pCount > 1) {

            // }
            // this.ref.text.style.height = this.ref.text.querySelector("p").offsetHeight + "px";

            // window.addEventListener("resize",()=>{
            //     this.ref.text.style.height = this.ref.text.querySelector("p").offsetHeight + "px";
            // })
        }
    }

    showMore(event) {
        let elm = event.target.closest("[data-action]");
        let text = elm.querySelector("[data-text]");

        this.ref.text.classList.add("transition");

        if (!elm.classList.contains("state--active")) {
            elm.classList.add("state--active");
            text.textContent = JSON.parse(text.dataset.text)[1];
            this.ref.text.style.height = this.ref.text.scrollHeight + "px";
        } else {
            elm.classList.remove("state--active");
            text.textContent = JSON.parse(text.dataset.text)[0];
            this.ref.text.style.height = this._textHeightInitial + "px";
        }
    }
});