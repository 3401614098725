application.register("part_ui_rating", class extends Stimulus.Controller {
    connect() {
        let elm = this.element;
        let default_values = elm.innerHTML;

        elm.addEventListener("mouseleave",()=>{
            elm.innerHTML = default_values;
        });

        elm.addEventListener("mouseenter",()=> {
            [...elm.querySelectorAll(".icon")].forEach((icon, i)=>{
                icon.addEventListener("click",()=>{
                    let input = elm.querySelector("input");
                    if(input !== null) {
                        let stars = (i+1).toString();
                        input.value = stars;
                        input.setAttribute("value",stars);
                        default_values = elm.innerHTML;
                    }
                });
                icon.addEventListener("mouseenter",()=>{
                    [...elm.querySelectorAll(".icon")].forEach((icon, j)=>{
                        icon.classList.remove("state--active");
                        if(j <= i) {
                            icon.classList.add("state--active");
                        }
                    });
                });
            });
        });
    }
});