window.lui_map_responses = {};

application.register("part_map", class extends Stimulus.Controller {

    get target() {
        return {
            map: this.targets.find("map"),
            infoWindow: this.targets.find("infoWindow"),
        }
    }

    connect() {
        let self = this, element = self.element, options = JSON.parse(element.dataset.options),
            markersUrl = element.dataset.markers;

        inView(element, () => {
            importScript([cdnjs.googlemaps.replace("{apikey}", options["apikey"]), cdnjs.googlemaps_cluster], () => {
                let coords = options["center"].split(/,\s/);
                let bounds;
                let markers_temp = [];
                let info_template = self.target.infoWindow.innerHTML;

                let map = new google.maps.Map(self.target.map, {
                    center: {lat: parseFloat(coords[0]), lng: parseFloat(coords[1])},
                    zoom: 12,
                    zoomControl: true,
                    mapTypeControl: false,
                    scaleControl: false,
                    streetViewControl: false,
                    rotateControl: false,
                    fullscreenControl: true,
                });

                let infoWindow = new google.maps.InfoWindow({
                    content: "",
                });

                let icon = {
                    url: '/img/marker.svg',
                    scaledSize: new google.maps.Size(40, 46),
                    anchor: new google.maps.Point(20, 46)
                };

                let iconFitness = {
                    url: '/img/marker-fitness.svg',
                    scaledSize: new google.maps.Size(40, 46),
                    anchor: new google.maps.Point(20, 46)
                };

                function getMarkers(url,callback) {
                    if (typeof window.lui_map_responses[url] === "undefined") {
                        fetch(url, {headers: {'X-Requested-With': 'XMLHttpRequest'}}).then((response) => {
                            return response.json();
                        }).then((payload) => {
                            window.lui_map_responses[url] = payload;
                            callback(payload);
                        });
                    }
                    else {
                        callback(window.lui_map_responses[url]);
                    }
                }

                function replaceAll(originalString, find, replace) {
                    return originalString.replace(new RegExp(find, 'g'), replace);
                }

                function generateMarker(item) {
                    let title = item["title"];
                    let point = new google.maps.LatLng(parseFloat(item["latitude"]), parseFloat(item["longitude"]));
                    let content = info_template;

                    content = replaceAll(content,"\\*title\\*",title);
                    content = replaceAll(content,"\\*image\\*",item["image"]);
                    content = replaceAll(content,"\\*location\\*",item["location"]);
                    content = replaceAll(content,"\\*text\\*",item["text"]);
                    content = replaceAll(content,"\\*url\\*",item["url"]);

                    let marker = new google.maps.Marker({
                        title: title,
                        map: map,
                        position: point,
                        icon: item["fitness"] ? iconFitness : icon,
                    });
                    bounds.extend(point);
                    markers_temp.push(marker);

                    function showInfo(marker) {
                        infoWindow.close();
                        infoWindow.setContent(content);
                        infoWindow.open(map, marker);
                    }

                    marker.addListener('click', () => {
                        showInfo(marker);
                    });

                    marker.addListener('mouseover', () => {
                        showInfo(marker);
                    });
                }

                map.addListener("click", () => {
                    infoWindow.close();
                })

                getMarkers(markersUrl, (payload) => {
                    bounds = new google.maps.LatLngBounds();
                    for (let item of payload) {
                        generateMarker(item);
                    }
                    let markerCluster = new MarkerClusterer(map, markers_temp, {
                        styles: [{
                            url: "/img/marker-cluster.svg",
                            width: 40,
                            height: 46,
                            fontFamily: "korolev-condensed,sans-serif",
                            fontWeight: "700",
                            textSize: 22,
                            textColor: "#FFFFFF",
                            textLineHeight: 40
                        }]
                    });

                    if (payload.length > 1) {
                        map.fitBounds(bounds);
                    } else {
                        map.setCenter({lat: payload[0].latitude, lng:payload[0].longitude});
                    }
                });
            });
        })
    }
});