application.register("comp_products_filter", class extends Stimulus.Controller {
    connect() {
        this.element.querySelector(".elm_comp_nav_wrapper").classList.remove("is--nav-end");
    }
    doSort(event) {
        event.preventDefault();
        let elm = event.currentTarget;
        let nav = elm.closest(".elm_comp_nav");

        if(!elm.classList.contains('state--active')) {
            nav.querySelector(".state--active").classList.remove("state--active");
            elm.classList.add("state--active");
            nav.scrollLeft = elm.closest(".col").offsetLeft - 20;

            application["getControllerForElementAndIdentifier"](document.querySelector(".part_filter"), "part_filter").doCatalogFilter(null,0,1);
        }
    }

    doView(event) {
        let elm = event.currentTarget;
        let type = elm.dataset.actionType;

        if(!event.currentTarget.classList.contains('state--active')) {
            this.element.querySelectorAll(".elm_comp_right").forEach((right) => {
                right.querySelector(".state--active").classList.remove("state--active");
                right.querySelector(`[data-action-type="${type}"]`).classList.add("state--active");
            })

            application["getControllerForElementAndIdentifier"](document.querySelector(".part_filter"), "part_filter").doCatalogFilter(null, 0, 1);
        }
    }

    doFilter(event, type) {
        event.preventDefault();
        let elm = event.currentTarget;
        let page = 1;
        let pageFrom;
        let pagingType = 'default';
        let loadingButton;

        if(typeof elm.dataset.append !== "undefined") {
            pagingType = 'append'
            loadingButton = elm;
            loadingButton.classList.add("state--loading");
            loadingButton.setAttribute("disabled",true);
        }
        if(typeof elm.dataset.page !== "undefined") {
            page = elm.dataset.page;
        }
        if(typeof elm.dataset.pageFrom !== "undefined") {
            pageFrom = elm.dataset.pageFrom;
        }
        else {
            pageFrom = page;
        }

        application["getControllerForElementAndIdentifier"](document.querySelector(".part_filter"), "part_filter").doCatalogFilter(null,0,page,pageFrom,pagingType,null,function() {
            if(typeof loadingButton !== "undefined") {
                loadingButton.classList.remove('state--loading');
                loadingButton.removeAttribute('disabled');
            }
        });
    }

    scrollSearchTop(event)
    {
        let toScroll = document.getElementById('search_results');
        LibAnchor.animation(toScroll);
    }

    navScroll(e) {
        let elm = e.currentTarget;
        let wrapper = elm.closest(".elm_comp_nav_wrapper");

        let left = Math.floor(elm.scrollLeft);
        let leftMax = Math.floor((elm.scrollWidth - elm.clientWidth));

        if(left === 0) {
            wrapper.classList.add("is--nav-start")
        }
        else {
            wrapper.classList.remove("is--nav-start")
        }

        if(left === leftMax) {
            wrapper.classList.add("is--nav-end")
        }
        else {
            wrapper.classList.remove("is--nav-end");
        }
    }
});