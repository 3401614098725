application.register("comp_products_detail", class extends Stimulus.Controller {
    get target() {
        return {
            totalPrice: this.targets.find("totalPrice"),
            totalPriceVat: this.targets.find("totalPriceVat"),
            totalCount: this.targets.find("totalCount")
        }
    }

    connect() {
        let selector = this.element;

        /*
        selector.addEventListener("change", function(e) {
            for (let target = e.target; target && target !== this; target = target.parentNode) {
                if (target.matches(`[data-product-variant]`)) {
                    (function(event) {
                        let variantId;
                        if(event.target.nodeName === "SELECT") {
                            variantId = target.value;
                            let uri = target.querySelector('option[value="' + variantId + '"]').dataset.uri;
                            if(uri !== undefined) {
                                swup.loadPage({
                                    url: uri,
                                    method: 'GET',
                                    data: {},
                                    customTransition: ''
                                });
                            }
                        }
                    }).call(target, e);
                    break;
                }
            }
        }, false);
        */

        document.querySelectorAll('[data-komplet-key]').forEach(function (elm){
            let key = elm.dataset.kompletKey;
            document.querySelector(`[name="kompletItem[${key}]"]`).value = elm.value;
        });
    }

    changeVariant(e) {
        let data = new FormData;
        data.append('productId', e.currentTarget.value);

        fetch(e.currentTarget.dataset.ajaxLink, {
            headers: {'X-Requested-With': 'XMLHttpRequest'},
            method: 'POST',
            body: data
        }).then((response) => {
            return response.json();
        }).then((payload) => {
            fn_ajaxHandler(payload);
        });
    }

    sharePage() {
        importScript(cdnjs.shareApiPolyfill,()=>{
            let keywords = document.head.querySelector('meta[name="keywords"]'),
                url = document.head.querySelector('meta[name="canonical"]'),
                description = document.head.querySelector('meta[name="description"]'),
                lang = document.documentElement.lang;

            if(["cs","de", "da", "en", "es", "fr", "ja", "ko", "nl", "pt", "ru", "sk", "tr", "zh"].indexOf(lang) === -1) {
                lang = "en";
            }

            navigator.share({
                    title: document.title,
                    text: (description !== null) ? description.getAttribute("content") : "",
                    url: (url !== null) ? url.getAttribute("content") : location.href,

                    // fbId: '123456789123456',
                    hashtags: (keywords !== null) ? keywords.getAttribute("content") : ""
                },
                {
                    copy: true,
                    email: true,
                    print: false,
                    sms: false,
                    messenger: true,
                    facebook: true,
                    whatsapp: true,
                    twitter: true,
                    linkedin: true,
                    telegram: false,
                    skype: false,
                    language: lang
                }
            )
                .then()
                .catch( error => console.log(error));
        });
    }

    setKompletItem(e) {
        let elm = e.currentTarget;
        let key = elm.dataset.kompletKey;
        document.querySelector(`[name="kompletItem[${key}]"]`).value = elm.value;
    }

    recalculate() {
        let totalPrice = 0;
        let totalPriceVat = 0;
        let totalCount = 0;

        document.querySelectorAll('[data-controller="comp_products_detail_row"]').forEach(function(elm) {
            let count = elm.querySelector('[data-target="comp_products_detail_row.count"]').value;
            let totalVat = elm.querySelector('[data-target="comp_products_detail_row.totalVat"]').innerText;
            let total = elm.querySelector('[data-target="comp_products_detail_row.total"]').innerText;

            totalPrice += parseFloat(total);
            totalPriceVat += parseFloat(totalVat);
            totalCount += parseInt(count);
        });

        this.target.totalPrice.innerHTML = totalPrice.toFixed(2);
        this.target.totalPriceVat.innerHTML = totalPriceVat.toFixed(2);
        this.target.totalCount.innerHTML = totalCount;
    }
});

application.register("comp_products_detail_row", class extends Stimulus.Controller {
    get target() {
        return {
            count: this.targets.find("count"),
            total: this.targets.find("total"),
            totalVat: this.targets.find("totalVat")
        }
    }

    recalculate(e) {
        let count = parseInt(e.currentTarget.value);

        this.target.total.innerHTML = (count * parseFloat(e.currentTarget.dataset.salePrice)).toFixed(2);
        this.target.totalVat.innerHTML = (count * parseFloat(e.currentTarget.dataset.salePriceVat)).toFixed(2);

        this.application.getControllerForElementAndIdentifier(document.querySelector('.comp_products_detail'), "comp_products_detail").recalculate();
    }
});