application.register("comp_products_compare", class extends Stimulus.Controller {
    get ref() {
        return {
            searchForm: this.targets.find("searchForm")
        }
    }

    toggleSearch() {
        this.ref.searchForm.classList.add("state--active");
        this.ref.searchForm.querySelector("input").focus()
    }

    connect() {
        let self = this, element = self.element;

        //TODO: drop jquery dependency

        importScript(cdnjs.jquery, () => {
            let $fn = $(element);
            let $window = $(window);

            if ($fn.length) {
                let $table = $fn.find(".elm_table"),
                    $table_show_initial = $fn.find(".elm_table").data("compare-show");

                const $fn_settings = {
                    sortable: true,
                    show: {
                        d: parseInt($table_show_initial),
                        t: 2,
                        m: 1,
                    }
                };
                var scrollTimeout;
                function fn_table_scroll() {
                    let $table_width = $table.width(),
                        $position = $table.scrollLeft();

                    clearTimeout(scrollTimeout);
                    scrollTimeout = setTimeout(function(){
                        let $td_visible;

                        if ($window.width() > 960) {
                            $td_visible = $fn_settings.show.d;
                        } else if ($window.width() > 767) {
                            $td_visible = $fn_settings.show.t;
                        } else if ($window.width() < 768) {
                            $td_visible = $fn_settings.show.m;
                        }

                        let $td_width = $table_width / $td_visible;
                        let overlap = $position % $td_width;

                        if (overlap > $table_show_initial) {
                            if(overlap > $td_width / 2) {
                                $table.animate( { scrollLeft: '+='+ ($td_width - overlap)}, 300);
                            } else {
                                $table.animate( { scrollLeft: '-='+ overlap}, 300);
                            }
                        }

                        if ($position+$table_width > $table.find("table").width()-$td_width/2) {
                            $table.parent().find("[data-compare-next]").attr("disabled","");
                        } else {
                            $table.parent().find("[data-compare-next]").removeAttr("disabled");
                        }

                        if ($position === 0) {
                            $table.parent().find("[data-compare-prev]").attr("disabled","");
                        } else {
                            $table.parent().find("[data-compare-prev]").removeAttr("disabled");
                        }
                    },250);

                    $fn.find(".elm_table_head .elm_inner").scrollLeft($position);
                }

                function fn_table_sizes() {
                    let $table_width = $table.width(),
                        $table_cell = $fn.find('.elm_table thead .elm_th, .elm_table_head .elm_th').not(".elm_autospace");

                    if ($window.width() > 960) {
                        $table_cell.css("width",Math.round($table_width/$fn_settings.show.d));
                    } else if ($window.width() > 767) {
                        $table_cell.css("width",Math.round($table_width/$fn_settings.show.t));
                    } else if ($window.width() < 768) {
                        $table_cell.css("width",Math.round($table_width/$fn_settings.show.m));
                    }

                    let $table_inner_width = $table.find("table").width();

                    $fn.find(".elm_table_head .elm_inner").css("width",$table_width);
                    $fn.find(".elm_table_head .elm_inner .elm_tr").css("width",$table_inner_width);

                    let max = 0;
                    $table.find('thead th').css("height","").each(function() {
                        max = Math.max($(this).outerHeight(), max);
                    }).css("height",max);

                    $table.find('.elm_tr_body').each(function() {
                        let td_max = 0;
                        $(this).find("td").css("height","").each(function(){
                            td_max = Math.max($(this).outerHeight(), td_max);
                        }).css("height",td_max);
                    });


                    let $get_cells = $table.find("thead .elm_th").not(".elm_autospace").length,
                        $autospace = $fn.find(".elm_autospace");

                    if ($window.width() > 960) {
                        if ($get_cells <= $table_show_initial) {
                            $autospace.show();
                        }
                        if ($get_cells <= $table_show_initial) {
                            $fn.find("[data-compare-prev],[data-compare-next]").hide();
                        }
                    } else if ($window.width() > 767) {
                        if ($get_cells === 1) {
                            $autospace.show();
                        } else {
                            $autospace.hide();
                        }
                        if ($get_cells <= 2) {
                            $fn.find("[data-compare-prev],[data-compare-next]").hide();
                        } else {
                            $fn.find("[data-compare-prev],[data-compare-next]").show();
                        }
                    } else {
                        if ($get_cells > 1) {
                            $fn.find("[data-compare-prev],[data-compare-next]").show();
                        }
                        $autospace.hide();
                    }

                    $fn.find(".wrp_comp_body").addClass("mod--loaded");
                }

                function fn_table_fixed() {
                    let $window_top = $window.scrollTop(),
                        $elm_top = $table.offset().top,
                        $elm_top_table = $table.find("tbody").offset().top,
                        $elm_bottom = $elm_top + $table.height() - $fn.find(".elm_table_head").height();

                    if ($window_top >= $elm_top) {
                        $fn.find(".elm_table_head").removeClass("state--active").addClass("position--fixed");
                        if ($window_top >= $elm_top_table) {
                            $fn.find(".elm_table_head").addClass("state--active");
                        }
                        if ($elm_bottom <= $window_top) {
                            $fn.find(".elm_table_head").removeClass("position--fixed").addClass("state--active");
                        }
                    } else {
                        $fn.find(".elm_table_head").removeClass("position--fixed state--active");
                    }
                }

                $fn.stop().on("click", "[data-compare-next]", function(e){
                    e.preventDefault();
                    let $table_width = $table.width(),
                        $item_width;
                    if ($window.width() > 960) {
                        $item_width = $table_width/$fn_settings.show.d;
                    } else if ($window.width() > 767) {
                        $item_width = $table_width/$fn_settings.show.t;
                    } else if ($window.width() < 768) {
                        $item_width = $table_width/$fn_settings.show.m;
                    }

                    $table.animate( { scrollLeft: '+='+$item_width }, 300);
                });
                $fn.stop().on("click", "[data-compare-prev]", function(e){
                    e.preventDefault();
                    let $table_width = $table.width(),
                        $item_width;
                    if ($window.width() > 960) {
                        $item_width = -($table_width/$fn_settings.show.d);
                    } else if ($window.width() > 767) {
                        $item_width = -($table_width/$fn_settings.show.t);
                    } else if ($window.width() < 768) {
                        $item_width = -($table_width/$fn_settings.show.m);
                    }

                    $table.animate( { scrollLeft: '+='+$item_width }, 300);
                });

                // $window.on("resize",fn_table_scroll);
                fn_table_scroll();
                $table.on("scroll",fn_table_scroll);

                fn_table_sizes();
                $window.on("resize",fn_table_sizes);

                fn_table_fixed();
                $window.on("scroll",fn_table_fixed);

                if ($fn_settings.sortable === true) {
                    importScript(cdnjs.sortable,function () {
                        Sortable.create($table.find("thead tr")[0], {
                            animation: 150,
                            ghostClass: "is--ghost",
                            handle: ".elm_handle",
                            draggable: ".elm_th_sortable",
                            scrollSensitivity: 50,
                            scrollSpeed: 20,

                            onEnd: function (evt) {
                                evt.oldIndex--;
                                evt.newIndex--;
                                if (evt.oldIndex !== evt.newIndex) {
                                    $table.find("tbody .elm_tr_body").each(function(){
                                        let $old = $(this).children().eq(evt.oldIndex + 1),
                                            $new = $(this).children().eq(evt.newIndex + 1),
                                            $old_clone = $old.clone();

                                        if (evt.oldIndex < evt.newIndex) {
                                            $new.after($old_clone);
                                        } else {
                                            $new.before($old_clone);
                                        }

                                        $old.remove();
                                    });

                                    let $old_head = $fn.find(".elm_table_head .elm_tr").children().eq(evt.oldIndex),
                                        $new_head = $fn.find(".elm_table_head .elm_tr").children().eq(evt.newIndex),
                                        $old_head_clone = $old_head.clone();

                                    if (evt.oldIndex < evt.newIndex) {
                                        $new_head.after($old_head_clone);
                                    } else {
                                        $new_head.before($old_head_clone);
                                    }

                                    $old_head.remove();
                                }
                            }
                        });
                    });
                }
            }
        });
    }
});