const LibTabs = function (element, callback) {
    let navAll = element.querySelector('[data-lib-tabs-nav]').querySelector(`[data-lib-tabs-item="all"]`);

    [...element.querySelector(`[data-lib-tabs-nav]`).querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((selector, index) => {
        selector.addEventListener("click", (e) => {
            e.preventDefault();

            [...selector.closest(`[data-lib-tabs-nav]`).querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((elm) => elm.classList.remove("state--active"));
            if(navAll !== null) {
                navAll.classList.remove("state--active");
            }
            selector.classList.add("state--active");

            [...element.querySelector(`[data-lib-tabs-area]`).children].forEach((elm) => elm.classList.remove("state--active"));

            element.querySelector(`[data-lib-tabs-area]`).children[index].classList.add("state--active");

            element.querySelector(`[data-lib-tabs-nav]`).scrollLeft = selector.closest(".col").offsetLeft - element.querySelector(`[data-lib-tabs-nav]`).offsetLeft - 48;

            if (callback) {
                callback(index);
            }
        })
    });

    if(navAll !== null) {
        navAll.addEventListener("click",(e) => {
            e.preventDefault();

            [...navAll.closest(`[data-lib-tabs-nav]`).querySelectorAll(`[data-lib-tabs-item="nav"]`)].forEach((elm) => elm.classList.remove("state--active"));

            navAll.classList.add("state--active");

            [...element.querySelector(`[data-lib-tabs-area]`).children].forEach((elm) => elm.classList.add("state--active"));
        })
    }
};