loadStimulus(document);

application.register("lib", class extends Stimulus.Controller {
    ripple(e) {
        LibRipple(e.currentTarget, e)
    }

    anchor(e) {
        e.preventDefault();
        LibAnchor.action(e.currentTarget);
    }
});

application.register("lib-dialog", class extends Stimulus.Controller {
    connect() {
        let element = document.querySelector('[data-lib-dialog-open]');

        if (element) {
            let url = element.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")) );
            } else {
                LibDialog.show(document.querySelector(element.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }
    }

    show(e) {
        let element = e.currentTarget;
        let url = element.getAttribute("data-action-url");

        LibDialog.action(element, url, () => loadStimulus(document.querySelector(".lib--dialog")));
    }

    hide() {
        LibDialog.hide();
    }
    cancelFilters(event) {
        application["getControllerForElementAndIdentifier"](document.querySelector(".part_dialog_filter .part_filter"), "part_filter").cancelFilters(event, () => {
            LibDialog.hide();
        });
    }
});

application.register("lib-tabs", class extends Stimulus.Controller {
    get target() {
        return {
            wrapper: this.targets.find("wrapper")
        }
    }

    connect() {
        LibTabs(this.element);
    }

    navScroll(e) {
        let elm = e.currentTarget;
        let left = Math.floor(elm.scrollLeft);
        let leftMax = Math.floor((elm.scrollWidth - elm.clientWidth));

        if(this.target.wrapper !== null) {
            if(left === 0) {
                this.target.wrapper.classList.add("is--nav-start")
            }
            else {
                this.target.wrapper.classList.remove("is--nav-start")
            }

            if(left === leftMax) {
                this.target.wrapper.classList.add("is--nav-end")
            }
            else {
                this.target.wrapper.classList.remove("is--nav-end");
            }
        }
    }
});

application.register("lib-tippy", class extends Stimulus.Controller {
    connect() {
        let element = this.element, options = this.data.get("options").replace(/\s/g,"").split(",");

        inView(element, () => {
            importScript([cdnjs.popper, cdnjs.tippy],()=> {
                importStyle(cdnjs.tippy_css);
                LibTippy(element, options);
            });
        });
    }
});

application.register("lib-slider", class extends Stimulus.Controller {
    connect() {
        inView(this.element, () => {
            LibSlider(this.element, JSON.parse(this.data.get("options")));
        });
    }
});

application.register("lib-gallery", class extends Stimulus.Controller {
    connect() {
        LibGallery(this.element, this.data.get("id"));
    }
});

application.register("lib-slimselect", class extends Stimulus.Controller {
    connect() {
        let self = this;
        importScript([cdnjs.jquery, cdnjs.slimselect], function() {
            LibSlimselect(self.element);
        });
    }
});

document.addEventListener("submit", function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(`form.part_form_search`)) {
            (function(event) {
                let input = event.target.querySelector('[name="search"]');
                if(input !== null) {
                    if(!input.value.length) {
                        event.preventDefault();
                    }
                }
            }).call(target, e);
            break;
        }
    }
}, false);

document.addEventListener("click", function(e) {
    for (let target = e.target; target && target !== this; target = target.parentNode) {
        if (target.matches(`[data-doc-click="basket-add"]`)) {
            (function(event) {
                document.body.insertAdjacentHTML("beforeend", '<div class="layout_summary_circle"></div>');
                let circles = document.querySelectorAll(".layout_summary_circle")
                let summary_circle =  circles[circles.length - 1];
                let basket;
                let left;

                if (document.getElementById("layout_header").classList.contains("headroom--not-top") && window.innerWidth > 960) {
                    basket = document.querySelector("#layout_header .wrp_header_nav .container");
                    left = basket.offsetLeft + basket.offsetWidth - 24 + "px";
                } else {
                    basket = document.querySelector("#layout_header .icon--paper-bag");
                    left = basket.offsetLeft + 24 + "px";
                }

                summary_circle.style.top = event.clientY + "px";
                summary_circle.style.left = event.clientX + "px";

                setTimeout(()=>{
                    summary_circle.style.top = basket.offsetTop + 24 + "px";
                    summary_circle.style.left = left;
                    summary_circle.style.width = "1rem";
                    summary_circle.style.height = "1rem";
                    summary_circle.style.opacity = "0.1"

                    setTimeout(()=>{
                        summary_circle.parentElement.removeChild(summary_circle);
                    },800)
                },10);
            }).call(target, e);
            break;
        }
    }
}, false);

application.register("lib-notification", class extends Stimulus.Controller {
    connect() {
        let self = this;

        importScript(cdnjs.slideElement, function() {
            const data = JSON.parse(self.element.getAttribute('data-lib-notification'))

            LibNotification.show({
                autoHide: true,
                title: data.title,
                type: data.type,
                message: data.message
            })
        })
    }
})
