application.register("part_filter", class extends Stimulus.Controller {
    connect() {
        inView(this.element, () => {
            let selector = this.element;
            let self = this;

            let range = selector.querySelectorAll(".part_ui_range");
            if (range !== null) {
                [...range].forEach((elm)=> {
                    importScript([cdnjs.autoNumeric, cdnjs.noui], ()=> {
                        LibNoUI(elm,{
                            elm_min: elm.querySelector(`[data-range-input="min"]`),
                            elm_max: elm.querySelector(`[data-range-input="max"]`),
                            elm_min_hidden: elm.querySelector(`[data-range-input="min"]`).nextElementSibling,
                            elm_max_hidden: elm.querySelector(`[data-range-input="max"]`).nextElementSibling,
                            unit: elm.dataset.unit
                        });
                    });
                });
            }

            selector.addEventListener("change", function(e) {
                for (let target = e.target; target && target !== this; target = target.parentNode) {
                    if (target.matches('[data-do-filter-change]')) {
                        self.doCatalogFilter(target,0,1);
                        break;
                    }
                    if (target.matches('[data-catalog-filter-check]')) {
                        self.doCatalogFilter(target,0,1);
                        break;
                    }
                }
            }, false);
        });
    }

    cancelFilters(event, callback) {
        event.preventDefault();
        [...document.querySelectorAll('[data-sort]')].forEach((elm)=>{elm.classList.remove("state--active")});
        [...document.querySelectorAll('[data-sort="recommended"]')].forEach((elm)=>{elm.classList.add("state--active")});

        [...document.querySelectorAll('[data-catalog-filter-check]')].forEach((elm)=>{elm.checked = false});
        this.priceRangeBackToDefaults();

        this.doCatalogFilter(event.currentTarget,0,1, null, null,null, callback);
    }

    priceRangeBackToDefaults() {
        let selector = this.element;
        let defaultMin = selector.querySelector('input[name="priceMinDefault"]');
        let defaultMax = selector.querySelector('input[name="priceMaxDefault"]');
        if(defaultMin !== null) {
            selector.querySelector('[data-range-input="min"]').value = defaultMin.value;
            selector.querySelector('[data-range-input="min"]').dispatchEvent(new Event('change', { 'bubbles': true }));
        }
        if(defaultMax !== null) {
            selector.querySelector('[data-range-input="max"]').value = defaultMax.value;
            selector.querySelector('[data-range-input="max"]').dispatchEvent(new Event('change', { 'bubbles': true }));
        }
    }

    doCatalogFilter(elem, redrawFiltersOnly,page,pageFrom,pagingType,fromSession,callback) {
        let self = this;
        let inDialog = 0;
        if(elem !== null) {
            inDialog = parseInt(this.element.getAttribute('data-in-filter'));
        }
        let filterValues = {};
        let selectorCatalogFilter = '.comp_products_filter [data-catalog-filter]';
        if(inDialog) {
            selectorCatalogFilter = '.wrp_dialog [data-catalog-filter]';
        }

        [...document.querySelectorAll(selectorCatalogFilter)].forEach((el)=>{
            let name = el.getAttribute('name');
            filterValues[name] = el.value;
        });

        let selectorCatalogFilterCheck = '.comp_products_filter [data-catalog-filter-check]';
        if(inDialog) {
            selectorCatalogFilterCheck = '.wrp_dialog [data-catalog-filter-check]';
        }

        [...document.querySelectorAll(selectorCatalogFilterCheck)].forEach((el)=>{
            if(el.checked === true) {
                let name = el.getAttribute('data-name');
                let value = el.value;
                if(typeof filterValues[name] === 'undefined') {
                    filterValues[name] = [];
                }
                filterValues[name].push(value);
            }
        });

        let sortType = (document.querySelector('.state--active[data-sort]') !== null) ? (document.querySelector('.state--active[data-sort]')).getAttribute('data-sort') : null;
        let searchQuery = (document.querySelector('.comp_products_filter') !== null) ? document.querySelector('.comp_products_filter').getAttribute('data-search-query') : null;
        let view = document.querySelector('[data-filter-view].state--active').dataset.filterView;

        let data = {
            filterData: filterValues,
            onlyFilters: (redrawFiltersOnly ? 1 : 0),
            page: (page ? page : 0),
            pageFrom: (pageFrom ? pageFrom : page),
            fromSession: (fromSession ? fromSession : 0),
            pagingType: (pagingType ? pagingType : 'default'),
            sortType: sortType,
            searchQuery: searchQuery,
            inDialog: inDialog,
            view: view
        };

        let dataUri = [];

        if((data.page && data.page) > 1 || (data.pageFrom && data.pageFrom > 1)) {
            dataUri.push('page=' + data.page);
            dataUri.push('pageFrom=' + data.pageFrom);
        }

        if(sortType !== null && sortType !== 'recommended') {
            dataUri.push('sortType=' + sortType);
        }

        if(view !== 'tiles') {
            dataUri.push('view=' + view);
        }

        var ignoreUrlParams = [];
        if(filterValues.priceMax && filterValues.priceMaxDefault && filterValues.priceMax === filterValues.priceMaxDefault) {
            ignoreUrlParams.push('priceMax')
            ignoreUrlParams.push('priceMaxDefault')
        }
        if(filterValues.priceMin && filterValues.priceMinDefault && filterValues.priceMin === filterValues.priceMinDefault) {
            ignoreUrlParams.push('priceMin')
            ignoreUrlParams.push('priceMinDefault')
        }

        for (let property in filterValues) {
            let itemData = filterValues[property];
            if(!ignoreUrlParams.includes(property)) {
                if(typeof itemData === 'string' || typeof itemData === 'number') {
                    dataUri.push(property + '=' + itemData);
                } else {
                    dataUri.push(property + '=' + itemData.join(','));
                }
            }
        }

        let filterElm = document.querySelector('.comp_products_filter');
        if(filterElm !== null) {

            clearTimeout(timeoutFilter);
            timeoutFilter = setTimeout(function () {
                filterElm.classList.add("state--loading");

                if(inDialog && document.querySelector("#snippet-filterProductsCount") !== null) {
                    document.querySelector("#snippet-filterProductsCount").classList.add("state--loading");
                }

                if (pagingType !== 'append') {
                    LibAnchor.animation(filterElm);
                }
                importScript(cdnjs.jquery,()=>{
                    $.ajax({
                        url: self.element.getAttribute('data-ajax-link'),
                        method: 'post',
                        dataType: 'json',
                        cache: false,
                        data: data
                    }).done(function (payload) {

                        window.history.replaceState({
                            url: (dataUri.length ? '?' + dataUri.join('&') : payload.appUrl),
                            random: Math.random(),
                            source: 'swup'
                        }, "", (dataUri.length ? '?' + dataUri.join('&') : payload.appUrl));
                        fn_ajaxHandler(payload, function () {
                            filterElm.classList.remove("state--loading");
                            if(inDialog && document.querySelector("#snippet-filterProductsCount") !== null) {
                                document.querySelector("#snippet-filterProductsCount").classList.remove("state--loading");
                            }
                            if (typeof callback !== 'undefined') {
                                callback();
                            }
                        });
                    });
                })
            }, 750);
        }
    }
});
