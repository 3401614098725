let cdnjs = {
    "picturefill": "https://fastly.jsdelivr.net/npm/picturefill@3.0.3/dist/picturefill.min.js",
    "cookieconsent": "https://packages.newlogic.cz/newlogic-cookieconsent/1.1.0/js/newlogic-cookieconsent.min.js",
    "recaptcha": "https://www.google.com/recaptcha/api.js?render={apikey}",
    "noui": "https://cdnjs.cloudflare.com/ajax/libs/noUiSlider/10.1.0/nouislider.min.js",
    "autoNumeric": "https://fastly.jsdelivr.net/npm/autonumeric@4.2.15/dist/autoNumeric.min.js",
    "flickity": "https://fastly.jsdelivr.net/npm/flickity@2.2.1/dist/flickity.pkgd.min.js",
    "fancybox": "https://fastly.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.js",
    "fancybox_css": "https://fastly.jsdelivr.net/npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css",
    "popper": "https://fastly.jsdelivr.net/npm/@popperjs/core@2.1.1/dist/umd/popper.min.js",
    "tippy": "https://fastly.jsdelivr.net/npm/tippy.js@6.1.0/dist/tippy-bundle.umd.min.js",
    "tippy_css": "https://fastly.jsdelivr.net/combine/npm/tippy.js@6.1.0/themes/light.min.css,npm/tippy.js@6.1.0/animations/scale.min.css",
    "slimselect": "https://fastly.jsdelivr.net/npm/slim-select@1.27.0/dist/slimselect.min.js",
    "slimselect_css": "https://fastly.jsdelivr.net/npm/slim-select@1.27.0/dist/slimselect.min.css",
    "jquery": "https://fastly.jsdelivr.net/npm/jquery@3.5.0/dist/jquery.min.js",
    "sortable": "https://fastly.jsdelivr.net/npm/sortablejs@1.10.2/Sortable.min.js",
    "shareApiPolyfill": "https://fastly.jsdelivr.net/npm/share-api-polyfill@1.0.18/dist/share-min.js",
    "googlemaps": "https://maps.googleapis.com/maps/api/js?key={apikey}",
    "googlemaps_cluster": "https://fastly.jsdelivr.net/npm/@google/markerclustererplus@5.1.0/dist/markerclustererplus.min.js",
    "slideElement": "https://fastly.jsdelivr.net/npm/slide-element@2.3.1/dist/index.umd.min.js"
};
