let swup;
if (typeof Swup !== "undefined") {
    window.history.scrollRestoration = 'manual';

    swup = new Swup({
        containers: ['#layout_main', '#layout_nav_swup','#layout_header','#layout_footer','#layout_nav'],
        cache: false,
        linkSelector: 'a[href^="' + window.location.origin + '"]:not([data-no-swup]):not([target="_blank"]):not([href^="/userfiles"]), a[href^="/"]:not([data-no-swup]):not([target="_blank"]):not([href^="/userfiles"]), a[href^="#"]:not([data-no-swup]):not([target="_blank"]):not([href^="/userfiles"])',
    });

    swup.popState = false;
    swup.scrollValues = {};

    swup.on('clickLink', function () {
        document.body.classList.remove("body--no-scroll");

        if (document.querySelector(".drawer-active") !== null) {
            document.querySelector("#layout_nav").classList.remove("state--active");
            document.querySelector("#layout_nav").classList.add("is--opacity");
            document.querySelector("#layout_nav").style.setProperty('--drawerOpacity', `0`);
            document.documentElement.style["overflow"] = "";
            document.documentElement.classList.remove("drawer-active");
        }

        if (document.querySelector(".lib--dialog") !== null) {
            LibDialog.hide();
        }

        if (document.querySelector("#layout_nav") !== null) {
            document.querySelector("#layout_nav").classList.remove("is--sub-active");
        }
    });

    document.addEventListener('swup:animationOutStart', () => {
        swup.scrollValues[window.location.href] = window.scrollY;
    });

    document.addEventListener('swup:animationOutDone', () => {
        window.scrollTo(0, 0);
    });

    document.addEventListener('swup:popState', () => {
        swup.popState = true;
    });

    swup.on('contentReplaced', function () {
        if (swup.scrollValues[window.location.href] !== "undefined" && swup.popState) {
            window.scrollTo(0, swup.scrollValues[window.location.href]);
            swup.popState = false;
        }

        swup.options.containers.forEach((selector) => {
            loadStimulus(document.querySelector(selector));
        });

        application.getControllerForElementAndIdentifier(document.body, 'lib-drawer').init()

        LibAnchor.init();

        const dialogElm = document.querySelector('[data-lib-dialog-open]');

        if (dialogElm) {
            let url = dialogElm.getAttribute("data-action-url");

            if (url) {
                LibDialog.action(dialogElm, url, () => loadStimulus(document.querySelector(".lib--dialog")) );
            } else {
                LibDialog.show(document.querySelector(dialogElm.dataset.libDialogOpen).innerHTML, () => loadStimulus(document.querySelector(".lib--dialog")));
            }
        }

        if (typeof fbq !== "undefined") {
            fbq('track', 'PageView');
        }

        if (typeof gtag !== "undefined") {
            let configs = [];
            window.dataLayer.forEach(function(config){
                if (config[0] === "config") {
                    if (typeof config[1] !== "undefined" && !configs.includes(config[1])) {
                        configs.push(config[1]);
                        gtag('config', config[1], {
                            'page_title': document.title,
                            'page_path': window.location.pathname + window.location.search
                        });
                    }
                }
            });
        }

        if (typeof window.seznam_retargeting_id !== "undefined") {
            importScript("https://c.imedia.cz/js/retargeting.js");
        }

        if (typeof LibCookieConsent !== "undefined") {
            LibCookieConsent.init();
        }

        document.body.classList.remove("body--preload");
        document.body.classList.add("body--loaded");

        if(typeof GAList !== 'undefined') {
            GAList = document.getElementById("layout_main").getAttribute("data-ga-list");
        }

        if (typeof window._adftrack !== 'undefined') {
            window._adftrack.push({
                pm: document.querySelector('[data-adform-id]').dataset.adformId,
                divider: encodeURIComponent('|'),
                pagename: encodeURIComponent(document.title)
            });

            (function () {
                var s = document.createElement('script');
                s.type = 'text/javascript';
                s.async = true;
                s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js';
                var x = document.getElementsByTagName('script')[0];
                x.parentNode.insertBefore(s, x);
            })();
        }

        if (typeof window.ecotrack !== 'undefined') {
            window.ecotrack('trackPageView');
        }
    });
}
