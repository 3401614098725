const LibSlider = function (element, options) {
    let autoplayInterval;
    let scrollLeftMax;

    if(document.documentElement.classList.contains("safari")) {
        importScript("https://polyfill.io/v3/polyfill.min.js?features=smoothscroll");
    }

    if(options === null) {
        options = {}
    }
    cssLoaded(()=> {
        scrollLeftMax = element.scrollWidth - element.clientWidth;

        if (typeof options["destroyDevices"] !== "undefined" && window.innerWidth < 960) {
            if(options["autoplay"]) {
                devicesAutoplay();
            }
            return false;
        }
        importScript(cdnjs.flickity, ()=> {
            let item_count = element.querySelectorAll(".col").length;
            let parent = element.closest("[data-ref-lib-slider]");

            if (typeof options["groupCells"] !== "undefined") {
                if (window.innerWidth < 768) {
                    options["groupCells"] = "25%"
                }
            }

            let options_all = {
                groupCells: (typeof options["groupCells"] !== "undefined") ? options["groupCells"] : "25%",
                cellAlign: (typeof options["cellAlign"] !== "undefined") ? options["cellAlign"] : "left",
                setGallerySize: true,
                autoPlay: options["autoplay"],
                pageDots: true,
                wrapAround: (typeof options["wrapAround"] !== "undefined") ? options["wrapAround"] : true,
                contain: true,
                adaptiveHeight: false,
                pauseAutoPlayOnHover: true,
                prevNextButtons: true,
                percentPosition: false,
                on: {
                    ready: function () {
                        if (element.querySelectorAll(".flickity-button[disabled]").length === 2) {
                            element.classList.add("flickity-nav-disabled");
                        }
                    },
                    scroll: function (progress) {
                        if(parent !== null) {
                            let progressBar = parent.querySelector("[data-lib-slider-progress]");
                            if(progressBar !== null) {
                                progress = Math.max(0, Math.min(1, progress ));
                                progressBar.value = progress * 100;
                            }
                        }
                    }
                }
            };

            let slider = new Flickity( element, options_all);

            element.classList.add("flickity-flex");

            slider.on("dragStart",()=>{
                [...slider.slider.childNodes].forEach(slide=>{
                    slide.style.pointerEvents = "none";
                })
            });

            slider.on("dragEnd",()=>{
                [...slider.slider.childNodes].forEach(slide=>{
                    slide.style.pointerEvents = null;
                })
            });

            function fn_arrows() {
                let slider_elm = element.querySelector(".flickity-slider");
                if(slider_elm !== null) {
                    let items_length =  slider_elm.childElementCount;
                    let items = slider_elm.children[0];
                    let items_width = items.offsetWidth + parseInt(getComputedStyle(items).marginLeft) + parseInt(getComputedStyle(items).marginRight);
                    let width = slider_elm.offsetWidth;

                    if (width >= (items_length * items_width)) {
                        [...element.querySelectorAll(".flickity-button")].forEach((elm)=>{elm.style.display = "none"});
                    } else {
                        [...element.querySelectorAll(".flickity-button")].forEach((elm)=>{elm.style.display = "block"});
                    }
                }
            }
            fn_arrows();

            window.addEventListener("resize",debounce(()=> {
                fn_arrows();
                element.classList.remove("flickity-flex");
                slider.resize();
                element.classList.add("flickity-flex");
            },250));

            if (typeof options["min"] !== "undefined") {
                if(typeof options["min"]["desktop"] !== "undefined" && window.innerWidth > 960) {
                    if(parseInt(options["min"]["desktop"]) > item_count) {
                        slider.destroy();
                    }
                }
            }

            if (typeof options["destroyDevices"] !== "undefined") {
                if (window.innerWidth < 960) {
                    slider.destroy();

                    if(options["autoplay"]) {
                        devicesAutoplay();
                    }
                }

                const mediaQuery = window.matchMedia('screen and (min-width : 60em)');
                mediaQuery.onchange = e => {
                    if (window.innerWidth > 960) {
                        clearInterval(autoplayInterval);
                        element.scrollLeft = 0;

                        slider = new Flickity( element, options_all);
                    } else {
                        slider.destroy();

                        if(options["autoplay"]) {
                            devicesAutoplay();
                        }
                    }
                }
            }
        });
    });

    function devicesAutoplay() {
        clearInterval(autoplayInterval);

        autoplayInterval = setInterval(() => {
            if(element.scrollLeft === scrollLeftMax) {
                element.scroll({ left: 0, behavior: 'smooth' });
            }
            else {
                element.scroll({ left: element.scrollLeft + element.children[0].clientWidth, behavior: 'smooth' });
            }
        }, options["autoplay"]);
    }
};