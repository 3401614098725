const LibTippy = function (element, options) {
    let content;
    let template;
    let interactive = true;
    let maxWidth = 350;
    let trigger = "mouseenter click";
    let placement = "top"

    if (options[0].length > 0) {
        maxWidth = 'none';
        template = document.querySelector(options[0]);
        content = template.innerHTML;

        if (typeof options[1] !== "undefined") {
            content = content.replace("{this}", options[1]);
        }
    } else {
        content = `${element.getAttribute("aria-label")}`;
    }

    if(typeof (element.dataset.libTippyType) !== "undefined" && element.dataset.libTippyType === "dropdown") {
        trigger = "click";
        placement = "bottom"
    }

    tippy(element, {
        maxWidth: maxWidth,
        content: content,
        allowHTML: true,
        theme: 'light',
        trigger: trigger,
        placement: placement,
        arrow: false,
        interactive: interactive,
        animation: "scale",
        flip: true,
        inertia: true,
        distance: 10,
        zIndex: 1000
    });
};
