application.register("part_item_advice", class extends Stimulus.Controller {

    get target() {
        return {
            answer: this.targets.find("answer"),
            button: this.targets.find("button")
        }
    }

    connect() {
        this._isInitialized = false;
        this._lng = JSON.parse(this.target.button.dataset.actionLng);
    }

    expand() {
        this.target.answer.style.height = this.target.answer.scrollHeight + "px";
        this.target.button.textContent = this._lng["hideAnswer"];
    }

    collapse() {
        if (!this._isInitialized) {
            this.target.answer.style.height = this.target.answer.offsetHeight + "px";
            this.target.answer.offsetHeight;
            this._isInitialized = true;
        }

        this.target.answer.style.height = "0px";
        this.target.button.textContent = this._lng["showAnswer"];
    }

    toggleAnswer() {
        if (this.target.answer.offsetHeight === 0) {
            this.expand();
        } else {
            this.collapse();
        }
    }
});