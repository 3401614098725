application.register("part_ui_upload", class extends Stimulus.Controller {
    get ref() {
        return {
            dropzone: this.targets.find("dropzone")
        }
    }
    connect() {
        if(typeof this.ref.dropzone !== "undefined") {
            importScript("https://fastly.jsdelivr.net/npm/dropzone@5.7.2/dist/dropzone.min.js",()=>{
                let files = [];
                if (this.ref.dropzone.dataset.uploadedFiles !== "") {
                    files = JSON.parse(this.ref.dropzone.dataset.uploadedFiles);
                }

                new Dropzone(this.ref.dropzone, {
                    url: "?do=uploadFile",
                    previewTemplate: "<div class=\"dz-preview dz-file-preview\"><img data-dz-thumbnail /><div class=\"dz-details\"><div class=\"dz-filename\"><span data-dz-name></span></div><div class=\"dz-size\" data-dz-size=\"Velikost:\"></div></div><div class=\"dz-progress\"><span class=\"dz-upload\" data-dz-uploadprogress></span></div><div class=\"dz-actions\"><a class=\"dz-remove\" data-dz-remove><i class=\"icon icon--close\"></i></a></div></div>",
                    init: function () {
                        let self = this;

                        if(typeof files !== "undefined" && files) {
                            files.forEach((file, index)=> {
                                let mockFile = {name: file.name, size: file.size, filePath: file.filePath};
                                self.displayExistingFile(mockFile, "/_temp/" + file.filePath);
                            });
                        }

                        this.on("removedfile", function (file) {
                            importScript(cdnjs.jquery, () => {
                                $.ajax({
                                    url: "?do=removeFile",
                                    method: 'post',
                                    data: {'filePath': file.filePath}
                                });
                            })
                        });

                        this.on("success", function (file, response) {
                            file.filePath = response.filePath;
                        });
                    }
                });
            })
        }
    }
});
