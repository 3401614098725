const LibNotification = {
    show: (data) => {
        const date = new Date()
        const id = [...document.querySelectorAll('.lib--notification .part_ui_notification')].length
        const uid = date.getTime() + date.getMilliseconds() + id
        let icon

        if (document.querySelector('.lib--notification') === null) {
            document.body.insertAdjacentHTML('beforeend', '<div class="lib--notification"></div>')
        }

        if (data.type === 'success') {
            icon = '<span class="icon--valid-circle"></span>'
        }

        if (data.type === 'error') {
            icon = '<span class="icon--invalid-circle"></span>'
        }

        if (data.type === 'warning') {
            icon = '<span class="icon--warning-circle"></span>'
        }

        if (data.type === 'info') {
            icon = '<span class="icon--info-circle"></span>'
        }

        document.querySelector('.lib--notification').insertAdjacentHTML('beforeend', `
            <div class="part_ui_notification background--color-${data.type}" data-id="${uid}" data-state="${data.type}">
                <button class="elm_notification_close part_ui_btn is--circle type--outline color--background" type="button" data-click="hide">
                    <span class="icon icon--close"></span>
                </button>
                <div class="wrp_notification_body row row--h-4">
                    <div class="col">
                        <div class="part_ui_icon size--medium">
                            ${icon}
                        </div>
                    </div>
                    <div class="col">
                        <span class="part_ui_status">${data.message}</span>
                    </div>
                </div>
            </div>
        `)

        const notification = document.querySelector(`.lib--notification .part_ui_notification[data-id="${uid}"]`)

        setTimeout(() => {
            notification.querySelector('[data-click="hide"]').addEventListener('click', () => LibNotification.hide(uid))
            notification.classList.add('is-animate-in')
            notification.style.display = 'block'
        }, 500 * (id / 2))

        if (data.autoHide) {
            setTimeout(() => {
                LibNotification.hide(uid)
            }, 10000 * ((id + 1) / 2))
        }
    },
    hide: (uid) => {
        const notification = document.querySelector(`.lib--notification .part_ui_notification[data-id="${uid}"]`)

        if (notification !== null) {
            notification.classList.add('is-animate-out')

            setTimeout(() => {
                SlideElement.up(notification, {
                    duration: 300
                }).then(() => {
                    notification.remove()
                    if ([...document.querySelectorAll('.lib--notification .part_ui_notification')].length < 1) {
                        document.querySelector('.lib--notification').remove()
                    }
                })
            }, 400)
        }
    },
    hideAll: () => {
        [...document.querySelectorAll('.lib--notification .part_ui_notification')].map((notification) => notification.classList.add('is-animate-out'))

        setTimeout(() => {
            [...document.querySelectorAll('.lib--notification .part_ui_notification')].map(notification => SlideElement.up(notification, { duration: 300 }))

            setTimeout(() => document.querySelector('.lib--notification').remove(), 300)
        }, 400)
    }
}
